import type { SearchOptions } from '@algolia/client-search';
import { all, call, put, select, take, takeLatest } from 'typed-redux-saga';

import logger from '@/domains/core/observability/logger';
import { selectVisitorId } from '@/domains/core/tracking/selectors/selectVisitorId';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { AlgoliaSearchInsights } from '@/domains/productDiscovery/algolia/searchInsights/AlgoliaSearchInsights';
import type { CustomAlgoliaSearchResults } from '@/domains/productDiscovery/Recommendation/interfaces/algolia';
import { ProductHistory } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.model';
import {
  productHistoryFetchedSelector,
  productHistorySelector,
} from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.selectors';
import { fetchRecommendationsByEngineGql } from '@/domains/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/services/fetchRecommendationsByEngine';
import { search } from '@/domains/productDiscovery/Recommendation/services/algolia/proxy.client';
import {
  FETCH_PRODUCT_HISTORY_SUCCESS,
  FetchProductHistorySuccess,
  getModelIdsRecommendationsFromProductHistorySuccess,
} from '@/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.actions';
import type {
  ProcessedRecommendation,
  Recommendations,
  RecommendationsByEngine,
} from '@/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/RecommendationsFromProductHistory.model';

const buildProductHistorySearchOptions = (
  productIds: number[] | string[] = [],
): SearchOptions => ({
  facetFilters: [
    productIds.map(
      (id, index) => `objectID: ${id}<score=${productIds.length - index}>`,
    ),
  ],
  attributesToRetrieve: ['model_id'],
  analyticsTags: ['RecommendationPlaylist', 'productHistory'],
});

function* getRecommendationsFromProductHistory(
  productIds: number[],
  recommendationEngines: string[],
) {
  try {
    const { userId } = yield* select(authSelector);
    const visitorId = yield* select(selectVisitorId);
    const userToken = userId
      ? yield* call(AlgoliaSearchInsights.calculateUserIdHash, String(userId))
      : visitorId;

    const searchParameters = buildProductHistorySearchOptions(productIds);

    const productHistoryModelIdsAlgoliaResponse: CustomAlgoliaSearchResults[] =
      yield call(search, searchParameters, userToken);

    const productHistoryModelIds: number[] =
      productHistoryModelIdsAlgoliaResponse.map((result) =>
        result.hits.map((hit) => hit.model_id),
      )[0];

    let recommendations: RecommendationsByEngine = {};

    if (productHistoryModelIds.length > 0) {
      recommendations = yield call(
        fetchRecommendationsByEngineGql,
        recommendationEngines,
        productHistoryModelIds,
      );
    }

    const processedRecommendations: ProcessedRecommendation[] =
      recommendations.recommendationEngines
        ? recommendations.recommendationEngines.map(
            (reco: Recommendations) => ({
              engineName: reco.engineName,
              modelIds: reco.productCollection.modelIds,
            }),
          )
        : [];

    if (processedRecommendations[0]) {
      yield put(
        getModelIdsRecommendationsFromProductHistorySuccess(
          processedRecommendations[0],
        ),
      );
    }
  } catch (error) {
    logger.error(error);
  }
}

function* getLatestProductHistory() {
  if (yield* select(productHistoryFetchedSelector)) {
    return yield* select(productHistorySelector);
  }

  while (true) {
    yield* take(FETCH_PRODUCT_HISTORY_SUCCESS);
    if (yield* select(productHistoryFetchedSelector)) {
      return yield* select(productHistorySelector);
    }
  }
}

export function createRecommendationFromProductHistorySaga(
  recommendationEngine: string,
  maxLastSeenProducts = 10,
) {
  function* saga(productHistory?: ProductHistory) {
    const productIds = productHistory
      ?.slice(0, maxLastSeenProducts)
      .map((prd) => prd.productId);

    if (productIds && productIds.length > 0) {
      yield* call(getRecommendationsFromProductHistory, productIds, [
        recommendationEngine,
      ]);
    }
  }

  return function* rootSaga() {
    yield* all([
      call(saga, yield* call(getLatestProductHistory)),
      takeLatest(
        FETCH_PRODUCT_HISTORY_SUCCESS,
        function* fetchProductHistorySuccess(
          action: FetchProductHistorySuccess,
        ) {
          yield* call(saga, action.history);
        },
      ),
    ]);
  };
}
