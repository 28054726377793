import { queryData } from '@/domains/core/graphqlClient/queryClient';
import { getPlatform, isB2B } from '@/domains/core/settings/utils';
import { RecommendationByEnginesDocument } from '@/domains/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/services/graphql/generated/recommendationsByEngine.query.generated';
import { type Platform } from '@/generated/graphql/types';

export const fetchRecommendationsByEngineGql = (
  engines?: string[],
  modelIds?: number[],
  limit?: number,
) =>
  queryData(
    RecommendationByEnginesDocument,
    {
      platformId: getPlatform().toUpperCase() as Platform,
      market: isB2B() ? 'B2B' : 'B2C',
      engineNames: engines || [],
      abtestPools: 'aaa',
      modelIds: modelIds || [],
      ...(limit && { limit }),
    },
    {
      'x-mm-page-type': isB2B() ? 'web_homepage_b2b' : 'web_homepage_b2c',
    },
  );
