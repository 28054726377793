import { useMemo } from 'react';

import { useDynamicSaga } from '@/domains/core/applicationState/hooks/useDynamicSaga';
import { useRecommendationsIds } from '@/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/hooks/useRecommendationsIds';
import { createRecommendationFromProductHistorySaga } from '@/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/RecommendationsFromProductHistory.saga';

type RecommendationEngine = 'FrequentlyBoughtTogether' | 'SimilarityML_v2';

export const useRecommendationsFromProductHistory = (
  recommendationEngine: RecommendationEngine,
  maxLastSeenProducts = 10,
) => {
  const sagaId = `modelIdsRecommendationsFromProductHistory_${recommendationEngine}_${maxLastSeenProducts}`;
  const saga = useMemo(
    () =>
      createRecommendationFromProductHistorySaga(
        recommendationEngine,
        maxLastSeenProducts,
      ),
    [recommendationEngine, maxLastSeenProducts],
  );
  useDynamicSaga(sagaId, saga);
  return useRecommendationsIds(recommendationEngine);
};
