import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';
import type { Recommendations } from '../interfaces/productRecommendation';
import type { ProductHistory } from '../modules/ProductHistory/ProductHistory.model';

export const selectProductHistoryPlaylist = (
  state: SpartacuxProductDiscoveryDomainState,
): Recommendations | undefined =>
  state.productDiscovery?.productHistory?.playlistHistory;

export const selectProductHistoryIds = (
  state: SpartacuxProductDiscoveryDomainState,
): ProductHistory | null =>
  state.productDiscovery?.productHistory?.productHistory || null;

export const selectProductHistoryPending = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean => state.productDiscovery?.productHistory?.fetching || false;

export const selectProductHistoryFetched = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean => state.productDiscovery?.productHistory?.fetched || false;

export const selectProductHistoryPlaylistError = (
  state: SpartacuxProductDiscoveryDomainState,
): string | null | boolean =>
  state.productDiscovery?.productHistory?.errorPlaylist || null;
