import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Recommendations } from '@/domains/productDiscovery/Recommendation/interfaces/productRecommendation';
import { ProductHistory } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/ProductHistory.model';

import {
  selectProductHistoryFetched,
  selectProductHistoryIds,
  selectProductHistoryPending,
  selectProductHistoryPlaylist,
} from '../../../selectors/productHistory';
import { fetchProductHistory } from '../ProductHistory.actions';

type UseProductHistoryReturnType = {
  playlists: Recommendations | undefined;
  productHistory: ProductHistory | null;
  hasBeenAlreadyRetrieved: boolean;
};

export const useProductHistory = (
  isTheComponentInView = true,
): UseProductHistoryReturnType => {
  const dispatch = useDispatch();
  const playlists = useSelector(selectProductHistoryPlaylist);
  const productHistory = useSelector(selectProductHistoryIds);
  const fetching = useSelector(selectProductHistoryPending);
  const fetched = useSelector(selectProductHistoryFetched);

  const mustFetchProductHistory = !fetching && !fetched && isTheComponentInView;
  useEffect(() => {
    if (mustFetchProductHistory) {
      dispatch(fetchProductHistory());
    }
  }, [dispatch, mustFetchProductHistory]);

  return {
    playlists,
    productHistory,
    hasBeenAlreadyRetrieved: fetched,
  };
};
