import type { FunctionComponent } from 'react';

import { PRODUCTS_IN_SEARCHBAR_RECO_ENGINE } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/constants';
import { useSearchSuggestionsVisibility } from '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchSuggestionsVisibility';
import { useProductHistory } from '@/domains/productDiscovery/Recommendation/modules/ProductHistory/hooks/useProductHistory';
import { useRecommendationsFromProductHistory } from '@/domains/productDiscovery/Recommendation/modules/ProductHistoryRecommendation/hooks/useRecommendationsFromProductHistory';

export const SimilarityProducts: FunctionComponent = () => {
  const suggestionsVisible = useSearchSuggestionsVisibility();
  useProductHistory(suggestionsVisible);
  useRecommendationsFromProductHistory(PRODUCTS_IN_SEARCHBAR_RECO_ENGINE);
  return null;
};
