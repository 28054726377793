import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import type { SpartacuxProductDiscoveryDomainState } from '@/domains/productDiscovery/productDiscovery.model';

const selectRecommendationsFromProductHistory = (
  state: SpartacuxProductDiscoveryDomainState,
) => state.productDiscovery?.productHistory?.recommendationsFromProductHistory;

export const modelIdsForRecommendationSelector = createSelector(
  [
    selectRecommendationsFromProductHistory,
    (
      state: SpartacuxProductDiscoveryDomainState,
      recommendationEngine: string,
    ) => recommendationEngine,
  ],
  (recommendations, recommendationEngine) =>
    recommendations?.[recommendationEngine] ?? [],
);

export const useRecommendationsIds = (recommendationEngine: string) => {
  return useSelector((state: SpartacuxProductDiscoveryDomainState) =>
    modelIdsForRecommendationSelector(state, recommendationEngine),
  );
};
